.preferenceDropdowns {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}
.preferenceDropdowns .ddl-form-custom-container {
  margin: 20px 50px 0px 0px;
}
.btnInputsection {
  margin: 20px;
}
.headerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}
.headerSection div:first-child {
  max-width: fit-content;
}
.headerSection .css-1sazv7p-MuiStack-root {
  margin-right: 20px;
}
