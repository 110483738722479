.containerClass{
    padding: 0 !important;
}
.Toastify__toast-container{
    padding: 0;
}
.Toastify__toast{
    border-radius: 0;
    box-shadow: none
}

.Toastify__toast-body{
    padding: 0;
}

.Toastify__toast-theme--colored.Toastify__toast--default{
  background-color: #f3f3f3;
}

.Toastify__close-button {
    display: none;
}